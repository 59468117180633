// gatsby-wrapper.js
import Theme from "./src/components/Theme";
import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n/config";

export const wrapRootElement = ({ element }) => (
  <Theme>
    <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
  </Theme>
);
