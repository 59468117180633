import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import { grey, green } from "@mui/material/colors";

const MuiThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const theme = createTheme({
    spacing: 4,
    palette: {
      text: {
        primary: grey[900], // 主なテキストカラー
        secondary: grey[600], // 二次的なテキストカラー
      },
      primary: {
        main: "#fff",
      },
      secondary: {
        main: green[900],
      },
      success: {
        main: green[500],
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MuiThemeProvider;
