import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // ユーザーの言語設定を検知するために必要
  .init({
    fallbackLng: "en", // デフォルトの言語を設定
    returnEmptyString: false, // 空文字での定義を許可に
    resources: {
      ar: {
        translation: require("./locales/ar.json"),
      },
      de: {
        translation: require("./locales/de.json"),
      },
      en: {
        translation: require("./locales/en.json"),
      },
      es: {
        translation: require("./locales/es.json"),
      },
      hi: {
        translation: require("./locales/hi.json"),
      },
      it: {
        translation: require("./locales/it.json"),
      },
      ja: {
        translation: require("./locales/ja.json"),
      },
      ko: {
        translation: require("./locales/ko.json"),
      },
      pt: {
        translation: require("./locales/pt.json"),
      },
      ru: {
        translation: require("./locales/ru.json"),
      },
      sv: {
        translation: require("./locales/sv.json"),
      },
      th: {
        translation: require("./locales/th.json"),
      },
      tr: {
        translation: require("./locales/tr.json"),
      },
      vi: {
        translation: require("./locales/vi.json"),
      },
      zh: {
        translation: require("./locales/zh.json"),
      },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      // 指定したHTMLノードを翻訳時にそのまま保持して表示するための設定
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "span"],
    },
  });

export default i18n;
